.product__form {
  --button__size: 30px;

  display: grid;
  gap: var(--size-xxs);
}

.modifier__wrapper {
  display: grid;
  gap: inherit;

  .modifier__title {
    font-size: var(--size-lg);
    font-weight: 700;
    color: var(--text-darkness);

    display: flex;
    line-height: 1;
    gap: var(--size-xxs);
    align-items: flex-end;
  }

  .modifier__required {
    padding: 3px 6px;
    font-weight: 500;
    font-size: var(--size-xxs);
    border-radius: 50px;
    background-color: var(--badge-required);
    color: var(--badge-required-text);
  }

  &:has(.modifier__counter),
  &:has(.modifier__checkbox) {
    gap: 0;
    > .modifier__counter,
    > .modifier__checkbox {
      border-bottom-width: 0.5px;
      border-color: var(--badge-new);

      display: grid;
      grid-template-columns: 1fr auto;

      padding-block: var(--size-base);
    }

    > .modifier__counter:last-of-type,
    > .modifier__checkbox:last-of-type {
      border-color: transparent;
    }
  }
}

.modifier__radio {
  display: flex;
  width: 100%;
  cursor: pointer;
  position: relative;
}

.modifier__radio input[type='radio'] {
  position: absolute;
  right: var(--size-base);
  top: 50%;
  transform: translateY(-50%);
}

.modifier__radio input[type='radio']:checked ~ label,
.modifier__radio:has(input[type='radio']:checked) label {
  border-color: var(--primary-active);
}

.modifier__radio {
  border: 2px solid transparent;
  border-radius: 12px;

  transition: all 0.1s ease-in-out;

  &:hover {
    border-color: var(--primary-active);
  }

  &:has(input[type='radio']:checked) {
    border-color: var(--primary-active);
  }

  label {
    border: 1px solid var(--primary-active);
    color: var(--text-dark);
    padding: var(--size-base);
    cursor: pointer;

    span {
      font-size: var(--size-base);
      font-weight: 500;
      color: var(--text-support);
    }

    &:hover {
      background-color: color-mix(in srgb, var(--primary-active) 3%, transparent);
    }
  }
}

.modifier__radio label,
.modifier__counter label,
.modifier__checkbox label {
  display: flex;
  width: 100%;
  border-radius: var(--size-xxs);
  flex-direction: column;

  font-size: 20px;
  font-weight: 500;
  gap: 0.25rem;

  transition: all 0.1s ease-in-out;

  span {
    font-size: var(--size-md);
    font-weight: 400;
  }
}

.modifier__checkbox {
  --checkbox__size: 1.5rem;
  padding-block: 0 !important;

  .label__wrapper {
    padding-block: var(--size-base);
  }
}

.modifier__checkbox:hover .checkbox__check {
  background-color: color-mix(in srgb, var(--primary-active) 10%, transparent);
}

.counter__container {
  display: flex;
  gap: 0.725rem;
  align-items: center;
  color: var(--primary-light);

  .counter__value {
    font-size: var(--size-md);
    font-weight: 400;

    font-variant-numeric: tabular-nums;
    min-width: 2ch;
    text-align: center;
  }

  .button__add,
  .button__subtract {
    transition: all 0.1s ease-in-out;
    width: var(--button__size);
    height: var(--button__size);

    svg {
      width: 100%;
      height: 100%;
    }

    &:focus-within {
      outline: none;
      background-color: color-mix(in srgb, var(--primary-active) 5%, transparent);
    }
  }

  .button__add:disabled,
  .button__subtract:disabled,
  .counter__value[disabled] {
    opacity: 40%;
    cursor: not-allowed;
    user-select: none;
    pointer-events: none;
  }
}

.product__observations {
  gap: var(--size-base);

  label {
    display: grid;
    gap: inherit;

    p {
      font-size: var(--size-lg);
      font-weight: 700;
    }

    span {
      font-size: var(--size-base);
      font-weight: 400;
      color: var(--text-light);
    }
  }
}

.textarea__input {
  border-radius: var(--size-xxs);
  outline: 1px solid transparent;
  border: 1px solid transparent;
  transition: colors 300ms ease-in-out, border-color 300ms ease-in-out, outline-color 300ms ease-in-out;
  field-sizing: content;
  margin-bottom: 1px;

  resize: none;

  max-height: 10lh;

  &:focus,
  &:active,
  &:hover {
    outline-color: var(--primary-active);
  }

  &::placeholder {
    color: var(--text-light);
  }

  &:disabled {
    opacity: 0.6;
  }

  &[data-size='sm'] {
    padding: var(--size-xxs) var(--size-base);
    font-size: 0.875rem;
  }

  &[data-size='md'] {
    padding: var(--size-base);
    font-size: var(--size-base);
  }

  &[data-size='lg'] {
    padding: 1.5rem;
    font-size: 1.125rem;
  }

  &[data-full='true'] {
    width: 100%;
  }

  &:not([data-invalid='true']) {
    background-color: transparent;
    border-color: var(--primary-light);
  }

  &[data-invalid='true'] {
    background-color: var(--baco-error-light);
    border-color: var(--baco-error-normal);
    color: var(--baco-error-normal);
  }
}
