.wrapper {
  --_height-img: 230px;
  --_spacer: 0.5rem;

  width: min(100% - var(--wrapper-padding), var(--wrapper-max-width));
  margin-inline: auto;
  display: grid;

  &:first-child {
    padding-top: var(--_spacer);
  }

  &:last-child {
    padding-bottom: var(--_spacer);
  }
}

.category__subtitle {
  font-size: 24px;
  font-weight: 700;
  padding-block: var(--_spacer);
  @apply text-baco-primary;
}

.product[data-loading='true'] {
  border: 1px solid transparent;
  outline: 1px solid transparent;
  @apply border-baco-carbon/30;
  pointer-events: none !important;

  .product__picture {
    > div {
      height: var(--_height-img);
      border-radius: 0;
    }
  }

  .product__header {
    padding-inline: var(--_spacer);

    div {
      border-radius: 4px;
    }
  }

  .product__info {
    padding-inline: var(--_spacer);
    padding-bottom: var(--_spacer);
  }
}

.product__layout {
  display: grid;
  gap: var(--_spacer);
  container-type: inline-size;

  &:where(:not([data-desktop])) {
    grid-auto-flow: column;
    grid-auto-columns: 75%;
    max-width: 100%;
    grid-template-columns: initial;
    overflow-x: auto !important;
    overscroll-behavior-inline: contain;
    scrollbar-width: thin;
    scroll-snap-type: x mandatory;
    scroll-behavior: smooth !important;
    padding-block: var(--_spacer);
    padding-inline: 1px;
    scroll-padding: 0.1rem;
    .product {
      scroll-snap-align: start;
      background-color: #fff;
    }
  }
}

.product__layout[data-show-img='false'] {
  .product {
    grid-row: span 2;
    padding: var(--_spacer);

    & > .product__badge {
      top: auto;
      left: auto;
      bottom: var(--_spacer);
      right: var(--_spacer);
    }

    .product__cart {
      top: auto;
      bottom: var(--_spacer);
      right: var(--_spacer);
    }

    .product__picture {
      display: none;
    }

    .product__header {
      padding: 0;
    }

    .product__info {
      padding: 0;
    }
  }
}

@media (430px < width < 768px) {
  .wrapper {
    --_height-img: 250px;
    --_spacer: 0.725rem;
    display: flex;
    flex-direction: column;
  }

  .product__layout {
    grid-auto-columns: 40%;
  }
}

@media (width >= 768px) {
  .wrapper {
    --_height-img: 280px;
    --_spacer: 1rem;
  }

  .product__layout {
    grid-template-columns: repeat(auto-fill, minmax(var(--_height-img), 1fr));
    grid-auto-flow: row;
  }
}

.product__badge {
  position: absolute;
  top: 0.5rem;
  left: 0.5rem;
  z-index: 1;
}

.product:has(.product__cart[data-on-cart='true']) {
  border-color: var(--complement-active);
  outline-color: var(--complement-active);
}

.product__cart {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  z-index: 1;
}

.product__cart--quantity {
  --_size: 24px;

  height: var(--_size);
  width: var(--_size);
  border-radius: 999999px;
  justify-content: center;
  align-items: center;
  display: flex;
  background-color: var(--complement-active);
  color: var(--mix);
  aspect-ratio: 1;
  font-weight: 700;
  font-size: 14px;
  padding: 4px;
}

.product__badge--new,
.product__badge--featured,
.product__badge--available {
  font-weight: 600;
  font-size: 14px;
  background-color: var(--contrast);
  border: none;
  padding: 2px 4px;
  border-radius: 4px;
  pointer-events: none;
  user-select: none;
  text-transform: uppercase;
  box-shadow: 0px 0px 2px 0px var(--border-color);
}

.product__badge--new {
  color: var(--badge-new);
}

.product__badge--featured {
  color: var(--badge-featured);
}

.product__badge--available {
  color: var(--badge-available);
}

.product__badge[data-show-badge='false'] {
  display: none;
}

.product {
  display: grid;
  grid-template-rows: subgrid;
  gap: var(--_spacer);
  border-radius: 6px;
  text-align: start;
  border: 1px solid var(--primary-active);
  outline: 1px solid transparent;
  position: relative;

  grid-row: span 3;

  overflow: hidden;

  transition: border-color 0.2s ease, outline-color 0.2s ease;

  &:hover {
    border-color: var(--success-text);
    outline-color: var(--success-text);
    img {
      transform: scale(1.02);
    }
  }

  &:active,
  &:focus-within,
  &:focus,
  &:focus-visible {
    border-color: var(--complement-active) !important;
    outline-color: var(--complement-active) !important;
  }

  .product__picture {
    width: 100%;
    overflow: hidden;

    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    height: var(--_height-img);
    aspect-ratio: 1 / 1 !important;
    overflow: hidden;

    img {
      backdrop-filter: blur(6px);
      height: 100%;
      width: 100%;
      object-fit: contain;
      transition: transform 0.1s ease-in-out;
      aspect-ratio: 1;
    }
  }

  .product__header {
    padding-inline: var(--_spacer);
    overflow: hidden;

    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: var(--_spacer);

    .product__name {
      color: var(--text-dark);
      font-size: 16px;
      font-weight: 700;
    }

    .product__dietary {
      display: flex;
      gap: 8px;
      align-items: center;
      text-transform: capitalize;
      max-width: 100%;
      flex-wrap: wrap;
      height: 1lh;
      margin-bottom: 1px;

      .dietary__indicator {
        font-weight: 600;
        font-size: 12px;
        background-color: var(--contrast);
        padding: 2px 4px;
        font-size: 10px;
        font-weight: 500;
        border-radius: 4px;
        display: flex;
        gap: 4px;
        pointer-events: default;
        color: var(--badge-new);
        border: 1px solid transparent;

        transition: border-color 0.2s ease;

        user-select: none;

        svg {
          width: 12px;
          height: 12px;
        }

        &:hover {
          border: 1px solid var(--border-color) !important;
        }
      }
    }

    .product__dietary[data-show-dietary='false'] {
      display: none;
    }
  }

  .product__info {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: var(--_spacer);

    padding-inline: var(--_spacer);
    padding-bottom: var(--_spacer);
    height: 100%;

    .product__description {
      color: var(--text-support);
      font-weight: 400;
      font-size: 14px;
      overflow: hidden;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      line-clamp: 2;
    }

    .product__price {
      color: var(--text-dark);
      font-size: 16px;
      font-weight: 500;
      display: flex;
      gap: 12px;
      align-items: flex-end;
      margin-top: auto;
      align-content: flex-end;

      .product__price--discounted {
        color: var(--error-text);
        text-decoration: line-through;
        font-size: 14px;
        font-weight: 500;
      }

      .product__price--original {
        color: var(--text-dark);
        font-size: 16px;
        font-weight: 500;
      }
    }
  }
}

.product__tags {
  background-color: var(--error-text);
  color: var(--mix);
  padding: 3px 6px;
  border-radius: 4px;
  font-size: 12px;
}

.product[data-is-available='false'] {
  pointer-events: none !important;
  @apply border-baco-carbon/30;

  picture {
    opacity: 0.5;
  }

  .product__header,
  .product__info {
    opacity: 0.5;
    filter: grayscale(1);
  }
}
