@import '../Product/index.css';

body:has(.cart__panel) {
  overflow: hidden;
}

.cart__drawer {
  row-gap: var(--space-md);
  display: grid;
  grid-template-columns: [full-start] var(--space-md) [content-start] 1fr [content-end] var(--space-md) [full-end];
  grid-template-rows: auto 1fr;
  min-height: 100dvh;
  padding-bottom: var(--space-md);
  background-color: var(--bacu-body);

  .header__with__button {
    grid-column: content;
    grid-row: 1;
    padding-top: var(--space-md);
    padding-inline: 0;

    .cart__title {
      margin: 0;
      width: 100%;
      text-align: end;
      font-size: var(--size-xl);
    }
  }

  .cart__content {
    grid-column: full;
    display: inherit;
    grid-template-columns: inherit;
    grid-row: 2;

    .cart__current-step {
      grid-column: full;
      display: inherit;
      grid-template-columns: inherit;
      grid-template-rows: 1fr auto;

      &:has(.cart__location__wrapper) {
        grid-template-rows: auto auto 1fr auto;
      }

      > * {
        grid-column: content;
        padding-inline: 0;
      }

      .cart__clear,
      .cart__item__card__edit {
        width: fit-content;
        margin-left: auto;
        font-weight: 400;
        font-size: var(--size-xs);
        color: var(--primary-light);
        text-decoration: underline;
        padding-block: var(--space-xs);
      }

      .cart__overview {
        grid-column: content;
        display: flex;
        flex-direction: column;

        > * {
          border-bottom: 0.5px solid var(--primary-light);
          padding: var(--size-base) 0;

          &:last-of-type {
            border-bottom: none;
            margin-bottom: 0;
          }
        }
      }

      .cart__product-wrapper:has(.counter__container) {
        --img-size: 90px;

        display: grid;
        column-gap: var(--space-md);
        row-gap: var(--space-xs);
        grid-template-columns: var(--img-size) 1fr auto;
        grid-template-rows: repeat(3, auto);
        margin: 0;

        > * {
          cursor: pointer;
        }

        .label__wrapper {
          grid-column: 1 / -1;
          grid-row: 1 / -1;
          display: inherit;
          column-gap: inherit;
          row-gap: var(--space-xs);
          grid-template-columns: inherit;
          grid-template-rows: inherit;

          .product__picture {
            grid-column: 1;
            grid-row: 1 / -1;
            max-width: var(--img-size);
            max-height: var(--img-size);
            width: 100%;
            height: 100%;
            border-radius: 0.5rem;
            border: 1.5px solid var(--primary-active);
            aspect-ratio: 1;
            overflow: hidden;
            div {
              padding: 0;
              span {
                font-size: 8px;
                padding: 0;
                margin: 0;
              }

              img {
                object-fit: contain;
              }
            }
          }

          .product__header {
            grid-column: 2;
            grid-row: 1;

            font-weight: 700;
            color: var(--text-darkness);
            font-size: var(--size-lg);
            @apply truncate;
          }

          .product__description {
            grid-column: 2;
            grid-row: 2;

            font-size: var(--size-md);
            color: var(--text-light);

            max-width: 36ch;
            overflow: hidden;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
          }

          .product__price {
            grid-row: 3;
            grid-column: 2;

            display: flex;
            gap: 0.5rem;
            align-items: flex-end;

            .product__price--original {
              color: var(--text-dark);
              font-size: var(--size-sm);
              font-weight: 500;
              line-height: 1;
            }

            .product__price--discounted {
              color: var(--text-light);
              font-size: var(--size-xs);
              font-weight: 400;
              text-decoration: line-through;
              line-height: 1;
            }
          }

          .cart__overview-item-not-available {
            grid-column: 1 / -1;
            grid-row: 1 / -1;
            display: grid;
            place-items: center;
            background-color: color-mix(in srgb, var(--text-darkness) 80%, transparent);
          }

          .cart__item__card__edit {
            grid-column: 3;
            grid-row: 1;
            color: var(--primary-light);
          }
        }

        .counter__container {
          grid-column: 2;
          grid-row: 4;

          .button__delete {
            grid-column: 3;
            grid-row: 4;
            color: var(--error-text);
            border-radius: var(--radius-sm);
            background-color: var(--contrast);

            &:hover,
            &:active {
              background-color: color-mix(in srgb, var(--error-text) 10%, transparent);
            }
          }
        }
      }
    }
  }
}

.cart__overview-empty {
  height: auto;
  padding-inline: 0;

  svg {
    width: 40%;
    height: auto;
  }

  p {
    color: var(--text-light);
    font-size: var(--size-md);
    font-weight: 400;
  }
}

.cart__overview__total {
  grid-column: full !important;
  position: sticky;
  bottom: 0;
  background-color: var(--bacu-body);
  z-index: 11;
  display: inherit;
  grid-template-columns: inherit;
  padding-top: var(--size-base);
  /* box-shadow: 0px -1px 28px 0px rgba(9, 30, 66, 0.2); */

  animation: fadeShadow linear both;
  animation-timeline: scroll();
  animation-range: entry 0% cover 100%;

  &[data-is-back='true'] {
    grid-template-rows: 1fr 1fr;
    row-gap: var(--space-md);

    .cart__overview__back {
      display: block;
      grid-column: content;
      grid-row: 1;
      color: var(--primary-light);
      border: 1.5px solid var(--primary-light);
      transition: background-color 0.3s;

      &:hover {
        background-color: var(--contrast);
      }
    }

    .cart__overview__total__button {
      grid-row: 2;
    }
  }

  .cart__overview__back {
    display: none;
  }

  .cart__overview__total__button {
    grid-column: content;
    display: flex;
    span span {
      padding: 0 var(--space-xs);
      border-right: 1px solid #e5e7eb;

      &:last-of-type {
        border-right: none;
      }
    }
  }
}

.cart__previous-step {
  display: none;
}

.cart__location__wrapper {
  --_column-width: 280px;
  grid-row: 1;

  display: grid;
  align-items: center;

  text-align: left;
  color: var(--text-light);

  + div:not([class]) {
    grid-row: 1;
    z-index: -1;
  }

  &[data-is-store='true'] {
    grid-template-columns: auto 1fr;
    grid-template-rows: 1fr 1fr;
    column-gap: var(--space-xs);

    .cart__location__title {
      grid-row: 1;
      grid-column: 2;
    }

    svg {
      --size-icon: 1.5rem;

      grid-row: 1 / -1;
      grid-column: 1;

      place-self: center;
      width: var(--size-icon);
      height: var(--size-icon);
      color: var(--primary-light);
    }

    .cart__location__address {
      grid-row: 2;
      grid-column: 2;

      color: var(--bacu-gray);
      font-size: var(--size-sm);

      max-width: calc(var(--_column-width) + 1.5rem);
    }
  }

  &[data-is-store='false'] {
    grid-template-columns: auto 1fr;
    grid-template-rows: auto 1fr;
    padding: var(--space-sm) !important;
    row-gap: var(--space-xs);
    column-gap: 0.25rem;
    border: 1.5px solid var(--primary-active);
    border-radius: 0.5rem;

    .cart__location__title {
      grid-row: 1;
      grid-column: 1 / -1;
    }

    svg {
      grid-row: 2;
      grid-column: 1;

      width: var(--size-base);
      height: var(--size-base);
      color: var(--text-light);
    }

    .cart__location__address {
      grid-row: 2;
      grid-column: 2;

      color: var(--text-light);
      font-size: var(--size-sm);
    }
  }

  .cart__location__title {
    font-weight: 700;
    font-size: var(--size-sm);
    color: var(--text-darkness);
  }

  .cart__location__address {
    font-weight: 400;
    text-decoration: underline;
  }
}

.cart__product-wrapper:has(.product__price[data-has-description='false']) {
  .label__wrapper {
    .product__price {
      grid-row: 2 !important;
    }
  }

  .counter__container {
    grid-row: 3 !important;
  }
}
