@import './accordion.css';
@import './form.css';
@import './product.css';

.product__wrapper {
  --column__width: 500px;

  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr auto;
  min-height: calc(100dvh - var(--scroll-padding));

  .product__footer {
    grid-row: 3;
    position: sticky;
    bottom: 0;
    z-index: 10;
    background-color: var(--bacu-body);
    padding: var(--space-md);

    animation: fadeShadow linear both;
    animation-timeline: scroll();
    animation-range: entry 0% cover 100%;
  }

  .product__footer__img {
    display: none;
  }
}

.Toastify__toast-body {
  flex: none !important;
  + .Toastify__close-button {
    margin-block: auto;
  }
}

@media (height < 600px) {
  .product__wrapper {
    --column__width: 400px;
  }
}

@media (width >= 1080px) {
  .product__wrapper {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto 1fr;
    position: relative;
    column-gap: 3rem;

    .header {
      grid-row: 1;
      grid-column: 1 / -1;
    }

    .product__footer {
      display: none;
    }

    .product__footer__img {
      display: grid;
      gap: var(--space-md);

      position: sticky;
      top: calc(var(--scroll-padding) + 2rem);
      align-self: start;
      grid-row: 2 / 3;
      grid-column: 1;
      height: fit-content;

      position: sticky;
      z-index: 10;
      max-width: var(--column__width);
      margin-left: auto !important;
      margin-inline: 0;
    }
  }

  .product__content {
    color: var(--text-darkness);

    display: grid;
    grid-template-rows: auto 1fr;
    grid-template-columns: 1fr 1fr;
    grid-column: 1 / -1;
    align-items: start;
    position: relative;
    row-gap: 2rem;
    column-gap: inherit;
    min-height: 100%;
    padding-bottom: var(--space-lg);

    .product__picture {
      display: none;
    }

    .product__info {
      grid-row: 1;
      position: relative;
      grid-column: inherit;
      grid-template-columns: inherit;
      gap: inherit;
      row-gap: 1rem;

      > * {
        height: fit-content;
      }

      .product__details {
        grid-row: 1;
        grid-column: 2;
      }
    }

    .product__form {
      max-width: var(--column__width);
      grid-row: 2;
      position: relative;
      grid-column: 2;
    }
  }
}

h1.product__title {
  color: var(--text-darkness);
  font-size: var(--size-xl);
  font-weight: 700;
}

@media (width > 644px) {
  .product__form {
    --button__size: 26px;
  }
}

@keyframes fadeShadow {
  from {
    box-shadow: 0px -1px 28px 0px rgba(9, 30, 66, 0.2);
  }
  to {
    box-shadow: 0 0 0 0 var(--bacu-body);
  }
}

@supports not (animation-timeline: view()) {
  .product__footer {
    box-shadow: 0px -1px 28px 0px rgba(9, 30, 66, 0.2);
  }
}
