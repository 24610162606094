.accordion__panel {
  display: grid;
  gap: var(--size-base);
}

.accordion__trigger {
  width: 100%;
  display: grid;
  text-align: left;
  border-radius: 0.5rem;
  grid-template-columns: 1fr auto;
  align-items: center;
  gap: 12px;
  padding-block: var(--size-base);
  overflow: hidden;
  outline: none;

  transition: background-color 500ms cubic-bezier(0.4, 0, 0.2, 1);

  .accordion__header {
    display: flex;
    flex-direction: column;
    gap: inherit;

    .accordion__counter {
      color: var(--primary-light);
      font-size: var(--size-base);
      font-weight: 500;
      display: inline-flex;
      align-items: center;
      gap: 4px;

      .accordion__counter-wrapper {
        display: inline-flex;
        align-items: center;
      }

      .accordion__counter-selected {
        font-variant-numeric: tabular-nums;
        font-feature-settings: 'tnum';
        min-width: 2ch;
        text-align: center;
      }
    }
  }

  &[aria-expanded='true'] .accordion__icon {
    rotate: 0deg;
  }

  .accordion__icon {
    rotate: 180deg;
    transition: all 500ms cubic-bezier(0.4, 0, 0.2, 1);
  }
}

.accordion__content {
  display: grid;
  grid-template-rows: 0fr;
  transition: grid-template-rows 500ms;

  > * {
    display: inherit;
    gap: var(--size-base);
  }

  &:not([aria-hidden='true']) {
    padding-bottom: var(--size-base);
  }

  > div {
    overflow: hidden;
  }

  &[aria-hidden='false'] {
    grid-template-rows: 1fr;
  }
}
