@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --primary-active: #147053;
  --complement-active: #f4973b;
  --success-text: #237233;
  --text-dark: #3a3f3f;
  --text-support: #667472;
  --badge-new: #205b2c;
  --badge-featured: #4592b2;
  --badge-available: #7c2712;
  --error-text: #db4d40;
  --complement-light: #fffaf5;
  --beverages-active: #0f5945;
  --beverages-bg: #0f594510;
  --beverages-hover: #064432;
  --beverages-pressed: #064432;
  --beverages-inactive: #dde0df;
  --beverages-text: #0f5945;
  --text-light: #576363;
  --text-lighter: #abb5b3;
  --text-darkness: #242828;
  --bacu-white: #ffffff;
  --bacu-gray: #424847;
  --bacu-gray-light: #f5f6f6;
  --badge-required: #ffb869;
  --badge-required-text: #62052a;

  --primary-light: #0f4535;
  --bacu-background: #fcf7f2;
  --bacu-body: #f9fafb;
  --logo-primary: #0f5946;

  --mix: hsl(0, 0%, 100%);
  --contrast: color-mix(in lab, currentColor 7%, var(--mix));
  --border-color: color-mix(in lab, currentColor 10%, hsla(0, 0%, 0%, 0.9));

  --size-base: 1rem;

  --size-xxs: 0.65rem; /* 10px */
  --size-xs: 0.75rem; /* 12px */
  --size-sm: 0.875rem; /* 14px */
  --size-md: 1rem; /* 16px */
  --size-lg: 1.125rem; /* 18px */
  --size-xl: 1.5rem; /* 24px */

  --space-xxs: clamp(0.25rem, 0.2rem + 0.25vw, 0.5rem);
  --space-xs: clamp(0.5rem, 0.4rem + 0.5vw, 0.75rem);
  --space-sm: clamp(0.75rem, 0.6rem + 0.75vw, 1rem);
  --space-md: clamp(1rem, 0.8rem + 1vw, 1.5rem);
  --space-lg: clamp(1.5rem, 1.2rem + 1.5vw, 2rem);

  --radius-sm: 0.25rem;
  --radius-md: 0.5rem;
  --radius-lg: 1rem;

  --wrapper-padding: 1.25rem;
  --wrapper-max-width: 430px;
}

@layer utilities {
  .box-shadow-up {
    -webkit-box-shadow: 0px -5px 10px 0px rgba(214, 214, 214, 0.75);
    -moz-box-shadow: 0px -5px 10px 0px rgba(214, 214, 214, 0.75);
    box-shadow: 0px -5px 10px 0px rgba(214, 214, 214, 0.75);
  }

  .payment-status-pop-up {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: auto;
    z-index: 99;
  }
}

@layer base {
  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

html {
  scroll-behavior: smooth;
  scroll-padding-top: var(--scroll-padding, 120px);
  scrollbar-gutter: stable;
  scrollbar-color: var(--primary-active) var(--complement-light);
  scrollbar-width: thin;

  interpolate-size: allow-keywords;

  font-family: 'DM Sans Variable', sans-serif;

  &:has(.input:focus) {
    scroll-padding-top: 0;
  }
}

html.search-focused {
  scroll-padding-top: 0;
}

body {
  margin: 0;
  overflow-x: hidden;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background-color: var(--bacu-body) !important;
}

select {
  background: url(/src/components/icons/SelectorArrow.svg) no-repeat 95% 50%;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
}

select option:disabled {
  display: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.yuno-modal-overlay {
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
  bottom: 0 !important;
  right: 0 !important;
  margin: auto !important;
  width: auto !important;
  height: auto !important;
  z-index: 98 !important;
}

.yuno-modal__scroll {
  position: absolute !important;
  width: 100%;
  height: 100%;
}

.alert-portal {
  position: relative;
  z-index: 999;
}

/* For Webkit-based browsers (Chrome, Safari and Opera) */
.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

/* For IE, Edge and Firefox */
.scrollbar-hide {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

/* TODO: improve when needed */
.checkbox__wrapper label {
  display: flex;
  width: 100%;
  border-radius: var(--size-xxs);
  flex-direction: column;

  font-size: var(--size-sm);
  font-weight: 400;
  gap: 0.25rem;

  transition: all 0.1s ease-in-out;

  span {
    font-size: var(--size-md);
    font-weight: 400;
  }
}

.checkbox__wrapper {
  --checkbox__size: 1.5rem;

  display: grid !important;
  grid-template-columns: auto 1fr;

  gap: 0.5rem;
  padding-block: var(--size-base);

  .label__wrapper {
    padding-block: var(--size-base);
    grid-column: 2;
  }

  svg,
  input {
    grid-column: 1;
  }
}

.checkbox__wrapper:hover .checkbox__check {
  background-color: color-mix(in srgb, var(--primary-active) 10%, transparent);
}

.checkbox__input {
  grid-column: 2;
  grid-row: 1;
  width: calc(var(--checkbox__size) + 3px);
  height: calc(var(--checkbox__size) + 3px);
  opacity: 0;

  cursor: pointer !important;
  z-index: 2;

  &:disabled {
    opacity: 50%;
    cursor: not-allowed;
    border-color: var(--text-support);
  }

  &:hover {
    border-color: var(--primary-active) !important;
    outline-color: var(--primary-active) !important;
  }
}

.checkbox__input:checked + .checkbox__check {
  background-color: color-mix(in srgb, var(--primary-active) 1%, transparent);
  stroke-dashoffset: 0;

  border-color: var(--primary-active) !important;
  outline-color: var(--primary-active) !important;
}

.checkbox__check {
  grid-column: 2;
  grid-row: 1;
  width: calc(var(--checkbox__size) + 3px);
  height: calc(var(--checkbox__size) + 3px);

  border: 1.2px solid var(--text-lighter);
  outline: 0.8px solid transparent;
  stroke: var(--primary-active);
  stroke-dasharray: 25;
  stroke-dashoffset: 25;
  stroke-linecap: round;
  stroke-width: 0.175rem;
  border-radius: 0.25rem;
  fill: none;
  transition: background 0.2s, stroke-dashoffset 0.3s;

  z-index: 1;

  &:hover {
    border-color: var(--primary-active) !important;
    outline-color: var(--primary-active) !important;
  }
}

[hidden] {
  display: none !important;
}

.header__back-button {
  color: var(--primary-light);
  border: 1.5px solid var(--primary-light);
  width: fit-content;
  height: fit-content;
  align-self: center;
  aspect-ratio: 1 / 1;
  border-radius: 50%;
  padding: 0.25rem;

  grid-column: 1;
}

.custom-toast-error {
  .Toastify__close-button {
    display: none !important;
  }
}

.toast-content {
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  gap: 12px;

  .toast-icon {
    --close-button-size: 0.825rem;
    position: absolute;
    right: 8px;
    top: 50%;
    transform: translateY(-50%);
    color: #e00000;
    border: 1.5px solid #e00000;
    border-radius: 50%;
    stroke-width: 1.5px;
    padding: 0.25rem;
  }
}

@media (430px < width < 768px) {
  :root {
    --wrapper-padding: 2rem;
    --wrapper-max-width: 664px;
  }
}

@media (width >= 768px) {
  :root {
    --wrapper-padding: 4rem;
    --wrapper-max-width: 1280px;
  }
}
