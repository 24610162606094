.header {
  --_spacer: var(--space-md);

  position: sticky;
  top: 0;
  padding: 0;
  z-index: 20;

  background-color: var(--bacu-white);

  padding-block: var(--_spacer);
  width: 100%;

  box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.05);

  .header__wrapper {
    display: grid;
    max-width: 100% !important;
    grid-template-rows: repeat(4, auto);
    grid-template-columns: 11fr 1fr 11fr;
    padding-bottom: 0rem;

    width: min(100% - var(--wrapper-padding), var(--wrapper-max-width));
    margin-inline: auto;
  }

  &[data-is-store='false'] {
    .header__wrapper {
      grid-template-columns: 11fr 1fr 10fr 1fr;
      grid-template-rows: auto;

      .header__location-store {
        display: flex;
        gap: 0.5rem;
        align-items: center;
        margin-left: auto;
        color: var(--primary-light);

        .header__location-text {
          font-size: var(--size-xs);
          font-weight: 400;

          color: var(--text-dark);

          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          width: auto;
          transition: all 300ms ease;

          animation: showAndHide 3s cubic-bezier(0.4, 0, 0.2, 1) forwards;
        }
      }

      .header__cart__button {
        grid-column: 4;

        display: flex;
        justify-content: flex-end;
        align-items: center;
      }
    }
  }

  .cart__location__wrapper {
    width: fit-content;
    grid-row: 1;
    grid-column: 1;
  }

  .header__search-bar {
    --_icon-size: var(--space-md);
    grid-row: 2;
    grid-column: 1 / -1;
    width: 100%;
    max-width: 100%;

    display: flex;
    justify-content: flex-end;
    gap: var(--space-md);
    height: fit-content;
    margin-top: var(--space-xs);
    margin-left: auto;
    /* padding-right: var(--_spacer); */
    position: relative;

    .input {
      width: 100%;
    }

    &:hover,
    &:focus-within {
      .icon__search {
        color: var(--primary-active);
      }
      .input {
        outline: none;
        border-color: var(--primary-active);
        background-color: transparent;
      }
    }

    .icon__search {
      position: absolute;
      top: 50%;
      left: var(--_icon-size);
      transform: translateY(-50%);
      color: #818f8d;
      transition: color 0.2s ease;
    }

    .input {
      background-color: var(--bacu-gray-light);
      padding: 12px 24px 12px calc(var(--_icon-size) + 24px);
      border-radius: var(--size-xxs);
      border: 1px solid var(--text-lighter);
      transition: all 0.2s ease;
      caret-color: var(--primary-active);
    }

    .input[type='search']::-webkit-search-cancel-button {
      -webkit-appearance: none;
      height: 16px;
      width: 16px;
      background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M15.5 8C15.5 12.1421 12.1421 15.5 8 15.5C3.85786 15.5 0.5 12.1421 0.5 8C0.5 3.85786 3.85786 0.5 8 0.5C12.1421 0.5 15.5 3.85786 15.5 8Z' fill='%230F4535'/%3E%3Cpath d='M6.125 6.125L9.875 9.875M9.875 6.125L6.125 9.875M15.5 8C15.5 12.1421 12.1421 15.5 8 15.5C3.85786 15.5 0.5 12.1421 0.5 8C0.5 3.85786 3.85786 0.5 8 0.5C12.1421 0.5 15.5 3.85786 15.5 8Z' stroke='%23F5F6F6' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
      cursor: pointer;
      margin-right: 8px;
    }

    .input[type='search']::-webkit-search-cancel-button:hover {
      opacity: 0.8;
    }
  }

  .header__cart__button {
    grid-row: 1;
    grid-column: 3;
    margin-left: auto;
    align-self: center;
    .button__cart {
      height: 100%;
      margin-block: auto;
      color: var(--primary-light);
      position: relative;

      .cart__total {
        --size__total: 1.2rem;

        position: absolute;
        top: -0.65rem;
        right: -0.65rem;
        background-color: var(--contrast);
        color: var(--primary-active);
        border: 1px solid var(--primary-active);
        font-size: 14px;
        font-weight: 400;

        aspect-ratio: 1;
        border-radius: 99999px;

        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        width: var(--size__total);
        height: var(--size__total);
      }
    }
  }

  .categoryName {
    grid-row: 4;
    grid-column: 1 / -1;

    display: grid;
    grid-template-columns: auto 1fr auto;
    grid-template-rows: auto;
    place-content: center;

    .scroll-button {
      color: var(--primary-light);
    }

    .scroll-button[disabled] {
      opacity: 0.5;
      pointer-events: none;
      cursor: not-allowed;
      background-color: red;
    }

    .scroll-button[data-show='false'] {
      display: none;
    }

    .scroll-button--left {
      grid-column: 1;
      grid-row: 1;
    }

    .scroll-button--right {
      grid-column: 3;
      grid-row: 1;

      rotate: 180deg;
    }

    .categoryName__list {
      grid-column: 2;

      position: sticky;
      top: 0;
      background-color: inherit;
      margin-inline: auto;

      display: grid;
      grid-auto-flow: column;
      grid-auto-columns: max-content;
      max-width: 100%;

      overflow-x: auto;
      overscroll-behavior-inline: contain;
      scrollbar-width: none;

      scroll-snap-type: x mandatory;
      scroll-snap-stop: always;

      scroll-behavior: smooth !important;
      -webkit-overflow-scrolling: touch !important;

      .categoryName__list__wrapper {
        scroll-snap-align: start;
        scroll-snap-stop: always;

        display: block;
        width: 100%;
        flex: 1;

        .menu__item {
          display: block;
          width: 100%;
          color: var(--beverages-active);

          padding: 0.5rem;

          border-bottom: 2px solid transparent;

          transition: border-color 0.2s ease, color 0.2s ease, color 0.2s ease;

          &:hover {
            border-color: var(--success-text);
          }

          &:focus,
          &:active,
          &:focus-within {
            border-color: var(--primary-active);
            font-weight: 500;
            background-color: var(--beverages-bg);
          }
          &::after {
            content: attr(data-text);
            display: block;
            font-weight: 500;
            height: 0;
            overflow: hidden;
            visibility: hidden;
          }
        }

        .active {
          scroll-margin-inline: 50%;
          position: relative;
          z-index: 1;
          border-color: var(--primary-active);
          font-weight: 500;
          /* background-color: var(--beverages-bg); */
        }
      }
    }
  }
}

.header__category-wrapper {
  grid-row: 3;
  grid-column: 1 / -1;

  margin-inline: auto;
  width: fit-content;
  height: fit-content;
  font-size: 700 !important;
  margin-block: var(--space-sm);
  .header__category-type {
    background-color: transparent;
    border-radius: 0;

    padding: 0 var(--space-xs);
    border-right: 1px solid var(--primary-active);

    &:hover {
      background-color: transparent;
    }

    &:first-child {
      padding-inline-start: 0;
    }

    &:last-child {
      padding-inline-end: 0;
      border-right: none;
    }
  }

  .button-group__item {
    text-transform: capitalize;
    font-weight: 600;
    font-size: var(--size-sm);

    > span {
      padding:5px 10px !important;
      background-color: transparent;
      border-radius: 0.25rem;
      border: 1px solid transparent;
      transition: all 0.2s ease;

      &:hover {
        border-color: var(--primary-light);
      }
    }
  }

  .button-group__item--active {
    > span {
      background-color: var(--primary-light);

      &:hover {
        background-color: var(--primary-active);
        background-color: color-mix(in srgb, var(--primary-light) 90%, transparent);
      }
    }
  }
}

.header__logo-button {
  width: fit-content;
  height: fit-content;
  align-self: center;
  justify-self: center;

  grid-column: 2;
  grid-row: 1;
  margin: 0 var(--space-md);

  .header__logo {
    width: 2.25rem;
    height: 2.25rem;
    aspect-ratio: 1 / 1;
    color: var(--logo-primary);
  }
}

@media (width > 768px) {
  .header__wrapper {
    --_column-width: 280px;
    grid-template-columns: 11fr 1fr 10fr 1fr !important;
    grid-template-rows: repeat(3, auto) !important;

    .header__location-text {
      animation: none !important;
    }
  }

  .cart__location__wrapper {
    .cart__location__title {
      font-size: var(--size-md) !important;
    }

    svg {
      --size-icon: 2rem !important;
    }

    .cart__location__address {
      font-size: var(--size-md) !important;
    }
  }

  .header__search-bar {
    grid-column: 3 !important;
    grid-row: 1 !important;

    max-width: var(--_column-width) !important;
    margin-top: 0 !important;
  }

  .header__category-wrapper {
    .button-gruop__item {
      span {
        padding: 10px 20px !important;
      }
    }
  }

  .header__cart__button {
    grid-column: 4 !important;
    grid-row: 1;
  }
}

@keyframes showAndHide {
  0% {
    max-width: 0;
    opacity: 0;
  }
  20%,
  70% {
    max-width: 13ch;
    opacity: 1;
  }
  100% {
    max-width: 0;
    opacity: 0;
  }
}
