.product__content {
  grid-row: 2;
  padding-top: var(--space-lg);
  display: grid;
  gap: 1rem;
  width: min(100% - var(--space-lg), 1400px);
  margin-inline: auto;

  .product__info {
    display: grid;
    position: relative;
    gap: 1rem;

    .product__details {
      display: inherit;
      gap: inherit;

      max-width: var(--column__width);
    }
  }

  span.product__title {
    font-size: var(--size-lg);
    font-weight: 700;
  }

  .product__description,
  .product__price--discounted {
    color: var(--text-light);
    font-size: 1rem;
    font-weight: 400;
  }

  .product__price--discounted {
    text-decoration: line-through;
    line-height: 1;
  }

  .product__price {
    color: var(--text-dark);
    font-size: var(--size-base);
    font-weight: 500;
    display: flex;
    gap: 0.5rem;
    align-items: flex-end;

    .product__price--original {
      color: var(--text-dark);
      font-size: var(--size-lg);
      font-weight: 500;
      line-height: 1;
    }
  }

  .product__tags {
    font-size: var(--size-xs);
    padding: 1px 8px;
  }
}

.product__content,
.product__footer__img {
  .product__picture {
    background-color: var(--bacu-white);
    overflow: hidden;
    max-height: var(--column__width);
    border: 2px solid var(--primary-active);
    border-radius: 1rem;

    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    aspect-ratio: 1 / 1 !important;

    img {
      backdrop-filter: blur(6px);
      height: 100%;
      width: 100%;
      object-fit: contain;
      transition: transform 0.1s ease-in-out;
      aspect-ratio: 1;
    }
  }
}
